import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./viewCertainBuyer.styles.css";
const ViewCertainBuyer = () => {
  const [certainCustomer, setCertainCustomer] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    let url = `https://fastcashbtcpay-server-production.up.railway.app/sell/${id}`;
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    };
    axios.get(url, { headers }).then((response) => {
      setCertainCustomer(response.data);
    });
  }, [id]);
  if (!certainCustomer) return null;

  return (
    <div>
      <div className="responsive-image">
        <center>
          <p style={{ color: "gold" }} className="payment-paragraph">
            Proof of payment:{" "}
          </p>
        </center>
        <img src={certainCustomer[0].imageUrl} alt="proof of payment" />
      </div>
    </div>
  );
};

export default ViewCertainBuyer;
