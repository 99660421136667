import { makeStyles } from "@material-ui/core";
import Homepage from "./Pages/HomePage";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import CoinPage from "./Pages/CoinPage";
import Header from "./components/Header";
import Sell from "./Pages/Sell";
import Login from "./Pages/Login";
import Customers from "./Pages/Customers";
import SellingSteps from "./Pages/SellingSteps";
import Register from "./Pages/Register";
import SellUsdt from "./components/sell/SellUsdt";
import CertainBuyer from "./Pages/CertainBuyer";
import Faq from "./Pages/Faq";

const useStyles = makeStyles(() => ({
  App: {
    backgroundColor: "#14161a",
    color: "white",
    minHeight: "100vh",
  },
}));

function App() {
  const classes = useStyles();

  return (
    <div className={classes.App}>
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/coins/:id" element={<CoinPage />} />
        <Route path="/sell/btc" element={<Sell />} />
        <Route path="/sell/usdt" element={<SellUsdt />} />
        <Route path="/admin/login" element={<Login />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/buyer" element={<CertainBuyer />} />
        <Route path="/selling/steps" element={<SellingSteps />} />
        {/* <Route path="/admin/register" element={<Register />} /> */}
        <Route path="/payment/proof/:id" element={<CertainBuyer />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
    </div>
  );
}

export default App;
