import React from "react";
import ViewCertainBuyer from "../components/ViewCertainBuyer/ViewCertainBuy";

const CertainBuyer = () => {
  return (
    <div>
      <ViewCertainBuyer />
    </div>
  );
};

export default CertainBuyer;
