import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TelegramIcon from "@material-ui/icons/Telegram";
import EmailIcon from "@material-ui/icons/Email";

import "./FaqComponent.styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const FaqComponent = () => {
  const classes = useStyles();
  return (
    <div>
      <div className="faq-heading">
        <h2 style={{ color: "gold" }}>FAQ</h2>
        <br />
        <h4 style={{ color: "gold" }}>Most frequent questions and answers</h4>
      </div>
      <center>
        <div className={classes.root}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography className={classes.heading}>
                WHAT’S YOUR SELLING POLICY?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="lists">
                <li>
                  <Typography>
                    Step One: Sell your coins using your wallet address
                  </Typography>
                </li>

                <li>
                  <Typography>
                    Step Two: Once transaction is successful, upload the
                    screenshot/proof of success to the transaction form provided
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Step Three: Upon receipt, seller will be paid within minutes
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">
              <Typography className={classes.heading}>
                WHO CAN SELL ON THIS PLATFORM?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We transact with sellers all over the world.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">
              <Typography className={classes.heading}>
                DO YOU HAVE CUSTOMER SERVICE?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Of course! Our friendly and knowledgeable customer services reps
                are available to answer your questions 24/7/365.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </center>
      <div className="icons">
        <a href="https://wa.me/+17167941702">
          <WhatsAppIcon
            style={{ padding: "1px", margin: "15px", color: "gold" }}
          />
        </a>

        <a href="https://instagram.com/fastcashbtcpay">
          <InstagramIcon
            style={{ padding: "1px", margin: "15px", color: "gold" }}
          />
        </a>
        <a href="https://www.facebook.com/FastCashBTCPAY">
          <FacebookIcon
            style={{ padding: "1px", margin: "15px", color: "gold" }}
          />
        </a>
        <a href="https://t.me/FastCashBTCPAY">
          <TelegramIcon
            style={{ padding: "1px", margin: "15px", color: "gold" }}
          />
        </a>
        <a href="mailto:btcpayfastcash@icloud.com">
          <EmailIcon
            style={{ padding: "1px", margin: "15px", color: "gold" }}
          />
        </a>
      </div>
    </div>
  );
};

export default FaqComponent;
