import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import WarningIcon from "@material-ui/icons/Warning";

import "./sellCoins.styles.css";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "35ch",
      display: "flex",
    },
  },
  input: {
    color: "fff",
  },
}));

const SellCoins = () => {
  const [account_holders_name, setAccountHoldersName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [bank_account_number, setBankAccountNumber] = useState("");
  const [bank_name, setBankName] = useState("");
  const [bitcoin_amount_in_dollar, setBitCoinAmountInDollar] = useState("");
  const [payment_proof, setPaymentProof] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("")
  const [copied, setCopied] = useState(false);

  let navigate = useNavigate();

  const handleClick = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log("Text copied to clipboard:", text);
      setCopied(true);
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      account_holders_name: account_holders_name,
      email: email,
      phone_number: phone_number,
      country: country,
      bank_account_number: bank_account_number,
      bank_name: bank_name,
      bitcoin_amount_in_dollar: bitcoin_amount_in_dollar,
      payment_proof: payment_proof,
    };
    const url = "https://fastcashbtcpay-server-production.up.railway.app/sell";
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    setLoading(true);
    try {
      let res = await axios.post(url, data, { headers });
      if (res.status === 200) {
        // navigate("/");
        setAccountHoldersName("");
        setEmail("");
        setPhoneNumber("");
        setCountry("");
        setBankAccountNumber("");
        setBankName("");
        setBitCoinAmountInDollar("");
        setPaymentProof("");
        setLoading(false);
        setSuccessMessage("Transaction is pending, you will be notified via email")
      } else {
        setError("An error occure, please try again");
      }
    } catch (err) {
      setLoading(false);
      setError("An error occure, please try again");
    }
  };
  const fileSelected = (event) => {
    const file = event.target.files[0];
    setPaymentProof(file);
  };

  const classes = useStyles();
  return (
    <div style={{ alignItems: "center", overflow: "scroll" }}>
      <center>
        <Typography variant="h3" style={{ padding: "43px", color: "gold" }}>
          Sell(BTC)
        </Typography>
        <h3>Copy BTC Wallet Address 👇</h3>
        <p onClick={() => handleClick("3JggCpapy5s8VyWeV4Honz3mbroN6AUMUb")}>
          3JggCpapy5s8VyWeV4Honz3mbroN6AUMUb
        </p>

        <br />

        <div style={{ paddingBottom: "23px" }}>
          <form
            // style={{ display: "inline-block" }}
            onSubmit={handleSubmit}
            className={classes.root}
            noValidate
            autoComplete="off">
            <TextField
              variant="outlined"
              id="outlined-basic"
              inputProps={{ style: { color: "#fff" } }}
              style={{ paddingBottom: "23px" }}
              type="text"
              label="Account Holders Name"
              InputLabelProps={{ style: { color: "gold" } }}
              value={account_holders_name}
              onChange={(e) => setAccountHoldersName(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              inputProps={{ style: { color: "#fff" } }}
              style={{ paddingBottom: "23px" }}
              label="Email"
              variant="outlined"
              type="email"
              InputLabelProps={{ style: { color: "gold" } }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              inputProps={{ style: { color: "#fff" } }}
              label="Phone Number"
              variant="outlined"
              InputLabelProps={{ style: { color: "gold" } }}
              style={{ paddingBottom: "23px" }}
              value={phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              inputProps={{ style: { color: "#fff" } }}
              label="Country"
              variant="outlined"
              InputLabelProps={{ style: { color: "gold" } }}
              style={{ paddingBottom: "23px" }}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              inputProps={{ style: { color: "#fff" } }}
              label="Bank Name"
              variant="outlined"
              InputLabelProps={{ style: { color: "gold" } }}
              style={{ paddingBottom: "23px" }}
              value={bank_name}
              onChange={(e) => setBankName(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              inputProps={{ style: { color: "#fff" } }}
              label="Bank Account Number"
              variant="outlined"
              InputLabelProps={{ style: { color: "gold" } }}
              style={{ paddingBottom: "23px" }}
              value={bank_account_number}
              onChange={(e) => setBankAccountNumber(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              inputProps={{ style: { color: "#fff" } }}
              label="BitCoin Amount In Dollar $"
              variant="outlined"
              InputLabelProps={{ style: { color: "gold" } }}
              style={{ paddingBottom: "23px" }}
              value={bitcoin_amount_in_dollar}
              onChange={(e) => setBitCoinAmountInDollar(e.target.value)}
            />
            <label
              htmlFor="file-upload"
              style={{
                justifyContent: "center",
                paddingBottom: "15px",
                color: "gold",
              }}>
              Upload Screenshot:
            </label>
            <input
              onChange={fileSelected}
              type="file"
              name="payment_proof"
              accept="image/*"
              style={{ paddingBottom: "23px" }}
            />
            {loading ? (
              <CircularProgress style={{ color: "gold" }} />
            ) : (
              <Button
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "#eebc1d",
                }}
                type="submit">
                Sell
              </Button>
            )}
            {successMessage && <p style={{color: "green"}}>{successMessage}</p>}
            {error && <p style={{ color: "red" }}>{error}</p>}
          </form>
        </div>
      </center>
    </div>
  );
};

export default SellCoins;
