import React from "react";
import FaqComponent from "../components/faq/FaqComponent";

const Faq = () => {
  return (
    <div>
      <FaqComponent />
    </div>
  );
};

export default Faq;
