import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    // minWidth: 500,
  },
});

const CustomerComponent = () => {
  const [customers, setCustomers] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const url = "https://fastcashbtcpay-server-production.up.railway.app/sell";
    // const url = "https://fastcashbtcpay-server-production.up.railway.app/sell";
    const headers = {
      "Content-Type": "application/json",
      Authorization: window.sessionStorage.getItem("token"),
    };
    axios.get(url, { headers }).then((response) => {
      setCustomers(response.data);
    });
  }, []);
  if (!customers) return null;

  const columns = [
    {
      id: "account_holders_name",
      label: "Account Holders Name",
      minWidth: 15,
      align: "center",
    },
    {
      id: "email",
      label: "Email",
      minWidth: 15,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "phone_number",
      label: "Phone Number",
      minWidth: 15,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "country",
      label: "Country",
      minWidth: 15,
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "bank_account_number",
      label: "Bank Account Number",
      minWidth: 15,
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "bank_name",
      label: "Bank Name",
      minWidth: 15,
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "bitcoin_amount_in_dollar",
      label: "BitCoin Amount in Dollar $",
      minWidth: 15,
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "Proof of payment",
      label: "proof of payment",
      minWidth: 15,
      align: "center",
    },
  ];

  return (
    <div>
      <h2 style={{ textAlign: "center", marginTop: "33px", color: "gold" }}>
        Customers
      </h2>
      <br />

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, color: "gold" }}>
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer, i) => {
              return (
                <StyledTableRow key={i}>
                  <StyledTableCell align="center">
                    {customer.account_holders_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {customer.email}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {customer.phone_number}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {customer.country}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {customer.bank_account_number}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {customer.bank_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    ${customer.bitcoin_amount_in_dollar}
                  </StyledTableCell>
                  <Link to={`/payment/proof/${customer.id}`}>
                    <StyledTableCell style={{ color: "blue" }} align="center">
                      Proof of payment
                    </StyledTableCell>
                  </Link>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CustomerComponent;
