import React from "react";
import CopyData from "../../utils/copyData.jpeg";
import Transfer from "../../utils/transferCash.jpeg";
import InputDetails from "../../utils/inputCashData.jpeg";

import "./selling-steps.styles.css";

import { Link } from "react-router-dom";
const SellingStepComponent = () => {
  return (
    <div>
      <center>
        <div className="ss-cc">
          <h1 style={{ color: "gold" }}>Trade Your Bitcoin</h1>
          <img alt="copy data" style={{ width: "43px" }} src={CopyData} />
          <h3>Copy our Wallet Address 👇</h3>
          <p>(BTC) 3JggCpapy5s8VyWeV4Honz3mbroN6AUMUb</p>
          <p>(USDT) TAFsWFyXBqgVsdjC3YBvcqqc17Csuu1Tf1</p>
        </div>
        <div className="ss-cc">
          <img style={{ width: "43px" }} src={Transfer} alt="Transfer cash" />
          <h3>Transfer</h3>
          <p>Send the bitcoin amount from your bitcoin wallet to our wallet.</p>
        </div>
        <div className="ss-cc">
          <img
            style={{ width: "43px" }}
            src={InputDetails}
            alt="input details"
          />
          <h3>Input your details</h3>
          <p>
            Fill out your details on the form and upload the screenshot of the
            confirmation page provided by your bitcoin wallet
          </p>
        </div>
        <Link to="/faq">
          <h4 className="proceed">Frequently Asked Questions(FAQ) / Support</h4>
        </Link>
        <h2 className="proceed">
          <Link to="/sell/btc">Proceed to Payment(BTC) -> </Link>
          <br />
          <br />
          <Link to="/sell/usdt">Proceed to Payment(USDT) -> </Link>
        </h2>
      </center>
    </div>
  );
};

export default SellingStepComponent;
