import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "./Login.styles.css";
import { CircularProgress } from "@material-ui/core";

const LoginComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  let navigate = useNavigate();

  let saveAuthTokenSession = (token) => {
    window.sessionStorage.setItem("token", token);
  };

  const login = async (email, password) => {
    setLoading(true);
    try {
      let res = await axios.post(
        "https://fastcashbtcpay-server-production.up.railway.app/user/signin",
        {
          email,
          password,
        }
      );
      if (res.status === 200) {
        saveAuthTokenSession(res.data.token);
        navigate("/customers");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setError("Incorrect Login details.");
    }
  };
  return (
    <div className="form-wrapper">
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          login(email, password);
        }}>
        <div className="input-container">
          <label className="label">Email: </label>
          <input
            type="email"
            name="email"
            className="input"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          />
        </div>
        <div className="input-container">
          <label className="label">Password: </label>
          <input
            type="password"
            name="password"
            className="input"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value.toLowerCase())}
          />
        </div>
        {loading ? (
          <CircularProgress style={{ color: "gold" }} />
        ) : (
          <button
            style={{ backgroundColor: "gold" }}
            type="submit"
            id="login-btn">
            Login
          </button>
        )}
        {error && <p style={{ color: "red" }}>{error}</p>}
      </form>
    </div>
  );
};

export default LoginComponent;
