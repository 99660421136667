import React from "react";
import CustomerComponent from "../components/customers/CustomersComponent";

const Customers = () => {
  return (
    <div>
      <CustomerComponent />
    </div>
  );
};

export default Customers;
