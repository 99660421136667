import { React, useState } from "react";
import {
  AppBar,
  Container,
  // MenuItem,
  Select,
  Toolbar,
  // Menu,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import { CryptoState } from "../CryptoContext";
import "./header.styles.css";

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    type: "dark",
  },
});

function Header() {
  const { currency, setCurrency } = CryptoState();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar color="transparent" position="static">
        <Container>
          <Toolbar>
            <Link
              to="/"
              // variant="h6"
              style={{ color: "gold", fontWeight: "bold" }}
              className="title">
              FastCashBTCPay
            </Link>
            <Link to="/selling/steps">
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                style={{ color: "gold" }}
                onClick={handleClick}>
                Sell
              </Button>
            </Link>
            {/* <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <Link to="/sell/btc">
                <MenuItem onClick={handleClose}>BTC</MenuItem>
              </Link>
              <Link to="/sell/usdt">
                <MenuItem onClick={handleClose}>USDT</MenuItem>
              </Link>
            </Menu> */}
            <Select
              variant="outlined"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currency}
              className="options"
              style={{ width: 100, height: 40, marginLeft: 15, color: "gold" }}
              onChange={(e) => setCurrency(e.target.value)}>
              <MenuItem value={"USD"}>USD</MenuItem>
              <MenuItem value={"EUR"}>EUR</MenuItem>
              <MenuItem value={"GBP"}>GBP</MenuItem>
              <MenuItem value={"NGN"}>NGN</MenuItem>
            </Select>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}

export default Header;
