import React from "react";
import SellCoins from "../components/sell/SellCoins";

const Sell = () => {
  return (
    <div>
      <SellCoins />
    </div>
  );
};

export default Sell;
