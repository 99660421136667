import React from "react";
import SellingStepComponent from "../components/sellingSteps/SellingStepComponent";

const SellingSteps = () => {
  return (
    <div>
      <SellingStepComponent />
    </div>
  );
};

export default SellingSteps;
